/* Base styles */
.homepage-container {
  min-height: 100vh;
  margin-bottom: 5rem; /* Adjusted for better spacing at the bottom */
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.7)), url('./bgimage-2.webp'); /* Overlay added here */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  background-attachment: fixed; /* Fix the background image during scroll on desktop */
  position: relative; /* Required for positioning */
}

.highlight-important {
  color: rgb(95, 175, 255); /* Blue color */
  font-weight: bold; /* Bold for emphasis */
}

.highlight-white {
  color: white; /* Default white text */
}


/* Mobile-specific adjustments */
@media (max-width: 1350px) {
  .homepage-container {
    background-attachment: scroll; /* Make background scroll with the page on mobile */
    background-size: cover; /* Keep it covering the entire area */
    background-position: center; /* Ensure it stays centered */
  }
}

/* Continue with your other styles */



.homepage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 1350px) {
  .client-my-static-bg-row {
      background-attachment: scroll; /* Disable fixed background */
      background-size: contain; /* Adjust background size to be more responsive */
      background-repeat: no-repeat; /* Prevent repeating the background */
  }}


.mission-statement h1 {
  font-weight: bold;
  line-height: normal;
  color: white;
  margin: 0 auto; /* Center-align the header */
  text-align: center; /* Ensure text alignment is centered for all sizes */
}

.mission-statement p {
  font-size: 1.4rem;
  font-weight: 400; /* Matches fw-normal */
  line-height: 1.5; /* Matches default line-height */
  color: white;
  margin: 0 auto; /* Ensure paragraph text is also centered */
  text-align: center;
  padding-top: 0.5rem; /* Align paragraph text center */
}

.mission-statement {
  padding-top: 3rem;
  text-align: center;
  padding-left: 6rem; /* Center-align text */
}

.logo-image {
  width: 80% ;
  max-width: 80%;
  height: auto;
  padding-top: 5rem;
  margin: 0 auto; /* Center the image */
}

.mega-button {
  font-size: large;
  font-weight: 600;
}



/* Adjustments for all device sizes */
@media (max-width: 992px) {
  .mission-statement {
    padding-left: 0; /* Remove left padding for all sizes below large */
    padding-right: 0;
    background-attachment: scroll !important; /* Fix the background image during scroll */
  }
 
}



/* Specific adjustments for mid-range devices */
@media (max-width: 1200px) and (min-width: 768px) {
  .mission-statement h1 {
    font-size: calc(2.8rem + (3.4 - 2.9) * ((100vw - 768px) / (1200 - 768))); /* Gradual decrease from 1200px to 768px */
  }
}

/* Further mobile-specific adjustments */
@media (max-width: 767px) {
  .mission-statement h1 {
    font-size: 2.8rem;
  }
    .mission-statement p {
      font-size: 1.2rem;}
  

  .homepage-container {
    min-height: 100vh;
    margin-bottom: 7rem; /* Adjusted for better spacing at the bottom */
  }
  .mission-statement p {
    font-weight: 400;
    padding-top: 0.5rem; /* Adjusted paragraph font size */
  }

  .mission-statement{
    padding-left: 0.5rem; /* Center-align text */
    padding-right: 0.5rem;
  }
  .logo-image {
    display: none; /* Hide the logo on smaller screens if desired */
  }
}

@media (max-width: 1400px) {
  .logo-image {
    width: 80%;
    max-width: 80%;
  }
  .responsive-title {
    font-size: 4rem;
  }
  
  
}

@media (max-width: 992px) {
  .logo-image {
    display: none;
  }
  
}




@media (min-width: 576px) { /* Small devices (landscape phones, 576px and up) */
  .responsive-title {
    font-size: 3rem;
  }
  .logo-image {
    width: 70%;
    max-width: 70%;
  }
}

@media (min-width: 768px) { /* Medium devices (tablets, 768px and up) */
  .responsive-title {
    font-size: 3.5rem;
  }
  .logo-image {
    width: 70%;
    max-width: 70%;
  }
  
}

@media (min-width: 992px) { /* Large devices (desktops, 992px and up) */
  .responsive-title {
    font-size: 4rem;
  }
  .logo-image {
    width: 70%;
    max-width: 70%;
  }
  
}

@media (min-width: 1300px) { /* Extra large devices (large desktops, 1200px and up) */
  .responsive-title {
    font-size: 4rem;
  }
  .logo-image {
    width: 65%;
    max-width: 70%;
  }
  
}

@media (max-width: 400px) {
  .mission-statement h1 {
    font-size: 2.4rem;
  }
    .mission-statement p {
      font-size: 1rem;}
  
}


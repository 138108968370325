.custom-wwo-section {
  position: relative;
  overflow: hidden;
  z-index: 1; /* Ensures that the content is above the background */
}

.wwo-header-text {
  margin-bottom: 0rem !important;
}

.services-header11 {
  margin-top: -3rem !important;
}

.read-more {
  font-size: large;
  position: relative; /* Make the read-more button relatively positioned */
  z-index: 2; /* Ensure it is above the circle animation */
}

/* Other styles */
.custom-my-5.custom-wwo-section {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 5rem;
}

.icon-imgs {
  padding-top: 1rem;
}

.custom-wwo-section .card-title {
  font-size: x-large; 
  font-weight: bold !important;
}

.custom-services-subheader {
  display: flex;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.5rem; /* Base font size */
  margin-bottom: 10px;
}

.custom-services-subheader h3 {
  color: #acacac;
  margin: 0;
  font-size: large; /* Adjust this as needed */
}

.xxl-container {
  margin-bottom: 10rem !important;
}

.custom-services-subheader .custom-line-icon {
  height: 1px;
  width: 80px; /* Base width */
  background-color: #acacac;
  margin-left: 10px;
}

.custom-wwo-section h2 {
  text-align: left !important;
  padding-bottom: 1rem;
  font-weight: bold;
  color: rgb(95, 175, 255);
  font-size: 2.5rem; /* Base font size */
  text-transform: uppercase;
}

.custom-wwo-section .card {
  margin: auto;
  max-width: calc(100% - 20px);
  background-color: rgba(0, 0, 0, 0.8) !important;
  
  border: 2px solid rgb(68, 161, 255) !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3) !important;
  color: white;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: background-color 0.5s ease, color 0.5s ease;
}

.custom-wwo-section .card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  transition: color 0.5s ease;
}

.custom-wwo-card {
 height: 280px !important; 
}

.custom-wwo-card .card-text {
  margin-bottom: 5rem !important;
}


.custom-wwo-card:hover {
  background-color: rgb(68, 161, 255)!important;
}

.custom-wwo-card:hover .card-title,
.custom-wwo-card:hover .card-text,
.custom-wwo-card:hover .read-more {
  color: white !important;
}

.custom-wwo-card:hover .circle-animation {
  animation: appearDiagonal 1s forwards;
}

.circle-animation {
  position: absolute;
  bottom: -50%; /* Start from outside the bottom */
  left: -50%;  /* Start from outside the left */
  width: 250px;
  height: 250px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: scale(0.75); /* Adjust scale to show more than before */
  transition: transform 1s ease;
  z-index: 1; /* Ensure it is behind the read-more button */
}

@keyframes appearDiagonal {
  to {
    bottom: 0%; /* Adjust to show more of the circle */
    left: 0%;   /* Adjust to show more of the circle */
    transform: scale(1) translate(-50%, 50%);
  }
}

.animate-background {
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1700 1000" width="1700" height="1000"><g fill="none" stroke="%231c8aff" stroke-width="2" opacity="0.3"><circle r="250" cx="100" cy="300"/></g><g fill="%231c8aff" opacity="0.15" stroke="none"><circle r="250" cx="200" cy="400"/></g></svg>');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: moveBackground 21s linear infinite;
}

@keyframes moveBackground {
  0% {
    background-position: center center;
  }
  50% {
    background-position: top left;
  }
  100% {
    background-position: center center;
  }
}

@media (max-width: 550px) {
.custom-wwo-card {
  height: 400px !important; 
 }
 .services-header11 {
  margin-top: -5rem !important;
}
}

@media (max-width: 420px) {
  .custom-wwo-card {
    height: 400px !important; 
   }
  }

  @media (max-width: 390px) {
    .custom-wwo-card {
      height: 450px !important; 
     }
    }
/* Responsive adjustments */
@media (max-width: 768px) {
  .custom-services-subheader {
    font-size: 1.2rem; /* Smaller font size for smaller devices */
  }

 

  .custom-services-subheader .custom-line-icon {
    width: 50px; /* Shorter line for smaller screens */
  }

  .custom-wwo-section h2 {
    font-size: 2rem; /* Smaller font size for h2 */
  }
}

/* Additional styles for responsive design */
@media (max-width: 1400px) {
  .icon-imgs {
    padding-top: 2rem;
  }
  
  .custom-wwo-section .card-title {
    margin-top: -4rem !important;
    font-size: x-large;
  }
}

@media (max-width: 992px) {
  .icon-imgs {
    padding-top: 0.5rem;
  }
  
  .custom-wwo-section .card-title {
    margin-top: -1rem !important;
    font-size: large;
  }
}

@media (max-width: 480px) {
  .custom-services-subheader {
    align-items: flex-start;
    font-size: small;
  }

  .icon-imgs {
    padding-top: 2rem;
  }
  
  .custom-wwo-section .card-title {
    margin-top: -2rem !important;
    font-size: x-large;
  }

  .custom-services-subheader .custom-line-icon {
    margin-left: 0.5rem;
    width: 50px;
    margin-top: 0rem;
  }

  .custom-wwo-section h2 {
    font-size: 1.7rem; /* Even smaller font size for very small devices */
  }
}

@media (max-width: 400px) {
  .custom-wwo-section .card-title {
    margin-top: -1.5rem !important;
  }
   
  .icon-imgs {
    padding-top: 1rem;
  }
}

@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

section {
  padding: 60px 0;
}

.section-title {
  text-align: center;
  color: rgb(68, 161, 255);
  margin-bottom: 50px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: -2rem;
}

#what-we-do .card {
  padding: 1rem !important;
  margin-bottom: 1rem;
  transition: .5s all ease;
  border: solid 3px whitesmoke !important;
  background-color: rgba(255, 255, 255, 0.2);
}

#what-we-do .card:hover {
  box-shadow: 0 10px 10px -5px rgb(68, 161, 255);
  background-color: white;
}

#what-we-do .card .card-block {
  padding-left: 50px;
  position: relative;
  flex: 1;
}

#what-we-do .card .card-block a {
  color: rgb(68, 161, 255) !important;
  font-weight: bold;
  text-decoration: none;
}

#what-we-do .card .card-block a.read-more {
  color: rgb(68, 161, 255)!important;
}

#what-we-do .card .card-block a i {
  display: inline-block; /* Ensure the arrow is always visible */
  transition: transform 0.3s ease; /* Add transition for the initial move effect */
}

#what-we-do .card:hover .card-block a i {
  animation: moveRight 1s forwards, moveBackForth 1.5s infinite; /* Apply the initial move and then back and forth animation */
}

@keyframes moveRight {
  to {
    transform: translateX(5px); /* Move the arrow to the right */
  }
}

@keyframes moveBackForth {
  0%, 100% {
    transform: translateX(5px); /* Start and end at the right position */
  }
  50% {
    transform: translateX(10px); /* Move further to the right */
  }
}

#what-we-do .card:hover .card-block a.read-more {
  color: white !important;
}

#what-we-do .card .card-block a i {
  padding-left: 5px;
}

#what-we-do .card:hover .card-block a i {
  display: inline-block;
  font-weight: bold;
}

#what-we-do .card .card-block:before {
  font-family: FontAwesome;
  position: absolute;
  font-size: 35px;
  color: rgb(68, 161, 255);
  left: 0;
  padding-right: 1rem !important;
  transition: transform .2s ease-in-out;
}

#what-we-do .card:hover .card-block:before {
  transform: rotate(360deg);
  transition: .5s all ease;
  color: white !important; /* Make icon white on hover */
}

#what-we-do .card .block-1:before {
  content: "\f109"; /* faLaptop */
}


#what-we-do .card .block-2:before {
  content: "\f10b"; /* fa-PHONE */
}

#what-we-do .card .block-3:before {
  content: "\f121"; /* faCode */
}

#what-we-do .card .block-4:before {
  content: "\f0a1"; /* faBullhorn */
}

#what-we-do .card:hover .card-block:before {
  transform: rotate(360deg);
  transition: .8s all ease;
}

@media (max-width: 768px) {
  .animate-background {
    background-color: white;
    background-image: none;
  }

  

}

.learn-more-circle1 {
  position: absolute;
  bottom: 20px; /* Position at the bottom with a small padding */
  left: 40px;
  height: 50px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  background-color: white; /* Light grey background for the circle */
  color: #1f1f1f; /* Semi-transparent black color for the arrow */
  padding: 0 15px;
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.learn-more-circle1:hover {
  background-color: rgb(68, 161, 255);
}

.learn-more-circle1:hover .learn-more-text,
.learn-more-circle1:hover .learn-more-arrow {
  color: white;
}

.learn-more-text {
  font-size: medium;
}

.card-case .arrow-circle {
  font-size: large;
}

.clickable-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 20px !important;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: rgb(240, 240, 240);
  margin-top: -2rem !important;
}



.custom-animate-background {
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1700 1000" width="1700" height="1000"><g><g fill="none" stroke="%231c8aff" stroke-width="2" opacity="0.3"><circle r="200" cx="150" cy="200"/></g><g fill="%231c8aff" opacity="0.15" stroke="none"><circle r="200" cx="300" cy="300"/></g></g><g transform="scale(-1, 1) translate(-1700, 0)"><g fill="none" stroke="%231c8aff" stroke-width="2" opacity="0.3"><circle r="200" cx="150" cy="200"/></g><g fill="%231c8aff" opacity="0.15" stroke="none"><circle r="200" cx="300" cy="300"/></g></g></svg>');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 30%;
  background-color: rgb(249, 249, 249);
  padding-top: 2rem;
  padding-bottom: 1rem !important; /* Adjusted to move closer to the top to align with header */
}






.comp-title {
  font-size: 3rem !important;
color: rgb(68, 161, 255) !important;
}

.comp-desc1 {
  color: rgb(46, 46, 46);
  margin-bottom: -2.5rem;
  font-size: 1.15rem;
  
}

.comp-text {
  color: rgb(46, 46, 46);
  margin-left: 12px;
  font-size: large;
}

.text-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.circle-arrow {
  color: #00000030; /* Semi-transparent black color for the arrow */
  font-size: large;
  height: 50px;
  width: 50px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  background-color: #f1f1f1; /* Light grey background for the circle */
  border-radius: 50%; /* Circular shape */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, background-image 0.3s ease; /* Smooth transition for the arrow and background image */
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2300000030' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><line x1='5' y1='12' x2='19' y2='12'></line><polyline points='12 5 19 12 12 19'></polyline></svg>");
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(-40deg); 
}

.clickable-container:hover .circle-arrow {
  transform: translate(5px, -5px) rotate(-40deg) !important; 
  background-color: rgb(68, 161, 255); /* Change to a blue background on hover */
  border-color: white; /* Change border color to white on hover */
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><line x1='5' y1='12' x2='19' y2='12'></line><polyline points='12 5 19 12 12 19'></polyline></svg>"); /* Change arrow color to white on hover */
}



.clickable-container:hover {
  background-color: rgb(68, 161, 255); /* Lighter background on hover */
}

.comp-head {
  font-weight: bold;
  color: rgb(68, 161, 255);
  margin: 10px;
}

/* Add hover effect on the clickable container */
.clickable-container:hover .comp-head,
.clickable-container:hover .comp-text {
  color: #ffffff; /* Change text color to white */
}

@media (max-width: 820px) {
  
  .comp-text {
    font-size: .9rem;
  }


}


@media (max-width: 768px) {
  
  .comp-text {
    font-size: large !important;
  }


}


@media (max-width: 480px) {
  .comp-title {
      font-size: 2.5rem !important;
      padding: 10px;
      margin-top: -2rem !important;
  }
  .comp-text {
    font-size: .9rem !important;
  }

  .clickable-container {
    margin-top: 0 !important;
  }

}

@media (max-width: 420px) {
  
  .comp-head {
    font-size: large;
  }

}

@media (max-width: 380px) {
  
  .comp-head {
    font-size: medium;
  }

  .comp-text {
    font-size: small !important;
  }

}
.case-study-section {
    padding: 2rem 0;
}

.case-study-header {
    margin-bottom: 2rem;
    text-align: left;
    margin-left: 1rem;
    margin-right: 1rem;
}

.case-study-header h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.case-study-header p {
    font-size: 1rem;
    color: #6c757d;
}

.case-study-content {
    text-align: left;
    margin-left: 1rem;
    margin-right: 1rem;
}

.case-study-content h3 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.case-study-content p {
    font-size: 1rem;
    line-height: 1.5;
}

.case-study-image {
    width: 100%;
    height: auto;
}

.case-links-header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.case-links .card {
    text-align: left;
    border: none;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    margin-left: 1rem;
    margin-right: 1rem;
}

.case-links .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.blog-text1 {
    font-size: large !important;
}

.case-links .card-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.case-links .card-text a {
    text-decoration: none;
    color: #333;
}

.case-links .card-text a:hover {
    color: rgb(95, 175, 255);
}

/* Responsive adjustments */
@media (max-width: 767.98px) {
    .case-study-header {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .case-study-content {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .case-links-header {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .case-links .card {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

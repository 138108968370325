/* clients.css */

.process-section {
  padding: 60px 15px;
  background-color: #ffffff;
}

.header-row .main-header1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: rgb(68, 161, 255) !important;
}

.header-row .sub-header1 {
  font-size: 1.25rem;
  color: #333333;
  margin-bottom: 2rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  margin: 0 15px;
  position: relative;
  z-index: 1;
}

.card-case {
  background-color: var(--card-background-color);
  box-shadow: 0px 3px 6px var(--card-box-shadow-1), 0px 8px 15px var(--card-box-shadow-2), 0 0 0 1px var(--card-border-color);
  padding: 60px 40px 40px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  height: 425px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s;
  text-align: left;
  overflow: hidden;
  cursor: default; /* Changed from pointer to default */
}

.card-case .image-circle {
  position: absolute;
  top: 25px;
  left: 35px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  border: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-case:hover {
  box-shadow: 0px 5px 10px var(--card-hover-box-shadow-1), 0px 15px 25px var(--card-hover-box-shadow-2), 0 0 0 1px var(--card-hover-border-color);
}

.card-case .icon {
  color: rgb(68, 161, 255);
}

.card-case .learn-more-circle {
  position: absolute;
  bottom: 10px;
  left: 30px;
  height: 50px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 25px;
  background-color: white;
  color: rgb(68, 161, 255);
  padding: 0 15px;
  margin-bottom: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s, transform 0.3s;
}

.learn-more-circle {
  text-decoration: none;
  color: inherit;
}

.learn-more-circle:hover {
  text-decoration: none;
}

.learn-more-circle:hover {
  background-color: rgb(68, 161, 255);
  color: white;
}

.card-case .learn-more-text {
  font-size: 0.9rem;
  font-weight: bold;
}

.card-case .arrow-circle {
  font-size: large;
}

.card-case h4 {
  margin: 12px 0 4px 0;
  font-family: inherit;
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--card-label-color);
}

.card-case p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.7;
  color: var(--text-color);
}

.card-case small {
  display: block;
  margin-bottom: 0.5rem;
  margin-top: 2.5rem;
  font-size: 0.9rem;
  color: var(--text-color);
}

@media (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .grid {
    display: flex;
    flex-wrap: wrap;
  }

  .card-case {
    flex: 1 1 calc(33.333% - 20px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .card-case {
    flex: 1 1 100%;
  }
}

@media  (max-width: 500px) {
  .header-row .main-header1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: rgb(68, 161, 255);
  }
  .header-row .sub-header1 {
    font-size: 1.15rem;
    color: #333333;
    margin-bottom: 2rem;
  }
}
.client-static-bg-container {
    position: relative;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    margin-top: 40px;
  }
  
  .client-review-container {
    position: relative;
    z-index: 2;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
  }
  
  .client-review-col {
    margin: 20px 0;
  }
  
  .testimonial-header {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 20px;
    color: whitesmoke; /* Change the color scheme */
  }
  
  .testimonial {
    margin: 0 20px 40px;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease; /* Add transition for smooth effect */
  }
  
  .testimonial:hover .testimonial-content {
    background-color: rgb(68, 161, 255); /* Change background to blue on hover */
    color: white; /* Change text to white on hover */
    transform: scale(1.05); /* Add scale effect on hover */
  }
  
  .testimonial .testimonial-content {
    padding: 35px 25px 35px 50px;
    margin-bottom: 35px;
    background: #fff;
    box-shadow: 0 0 10px rgba(68, 161, 255, 0.5),
                0 0 20px rgba(68, 161, 255, 0.4),
                0 0 30px rgba(68, 161, 255, 0.3),
                0 0 40px rgba(68, 161, 255, 0.2); /* Glowing box shadow */
    position: relative;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease; /* Add transition for smooth effect */
  }
  
  .testimonial .testimonial-content:after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #fff;
    position: absolute;
    bottom: -10px;
    left: 22px;
    transform: rotate(45deg);
    transition: background-color 0.3s ease; /* Add transition for smooth effect */
  }
  
  .testimonial:hover .testimonial-content:after {
    background: rgb(68, 161, 255); /* Change background to blue on hover */
  }
  
  .testimonial-content .testimonial-icon {
    width: 50px;
    height: 45px;
    background: rgb(68, 161, 255); /* Change the color scheme */
    text-align: center;
    font-size: 22px;
    color: #fff;
    line-height: 42px;
    position: absolute;
    top: 37px;
    left: -19px;
    transition: background-color 0.3s ease; /* Add transition for smooth effect */
  }
  
  .testimonial-content .testimonial-icon:before {
    content: "";
    border-bottom: 16px solid rgb(68, 161, 255); /* Change the color scheme */
    border-left: 18px solid transparent;
    position: absolute;
    top: -16px;
    left: 1px;
  }
  
  .testimonial:hover .testimonial-icon {
    background: rgb(255, 255, 255); /* Change background to white on hover */
    color: rgb(95, 175, 255); /* Change quote color to blue on hover */
  }
  
  .testimonial .description {
    font-size: 1.1rem;
    font-style: italic;
    color: #000000;
    line-height: 23px;
    margin: 0;
    transition: color 0.3s ease; /* Add transition for smooth effect */
  }
  
  .testimonial:hover .description {
    color: white; /* Change description text to white on hover */
  }
  
  .testimonial-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  
  .testimonial-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .testimonial .title {
    font-size: 1.25rem;
    font-weight: 700; /* Make the title bold */
    color: #ffffff;
    text-transform: capitalize;
    letter-spacing: 1px;
  }
  
  .testimonial .post {
    font-size: 1.1rem;
    font-weight: 600; /* Make the post bold */
    color: rgb(95, 175, 255); /* Change the color scheme */
  }
  
  .testi-1 {
    color: whitesmoke;
    margin-bottom: 2rem;
  }
  
  .client-review-profile-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid rgb(68, 161, 255); /* Add a border with the same blue color */
    margin-left: 15px;
    object-fit: cover;
  }

  @media (max-width: 769px) {
    .test-help {
        margin-top: 2rem !important;
    }
  }
  
  @media (max-width: 480px) {
    .testimonial-header {
        font-size: 2.5rem;
    }
    .test-help {
        margin-top: 2rem !important;
    }
  }
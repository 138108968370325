.video-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  cursor: pointer;
  z-index: 5;
}

.video {
  width: 100%;
  border-radius: 10px;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  z-index: 2;
}

.overlay.hidden {
  opacity: 0;
}

.custom-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 3;
  color: white;
}

.video-container:hover .custom-play-button {
  color: rgb(68, 161, 255);
}



.video-container:hover .overlay {
  opacity: 0;
}

.video-container:hover .custom-play-button {
  color: rgb(68, 161, 255);
}



.custom-play-button:hover {
  background-color: transparent;
}

.location-text {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-bottom-left-radius: 5px;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 3;
}

.video-container:hover .location-text,
.video-container.playing .location-text,
.location-text.visible {
  transform: translateY(0);
}

.gallery-image1 {
  width: 500px;
  height: auto;
  object-fit: cover;
}


  @media (max-width: 992px) {
    .overlay {
      display: none; /* or visibility: hidden; */
    }
  }
  
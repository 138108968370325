/* Vision Section Styles */
.about-jumbotron {
  min-height: 45vh; /* Adjust the height as needed */
  padding-top: 8rem !important; 
  background-color: black; 
  
}

.who-we {
  font-weight: 500;
}

.icon-1 {
  color: rgb(68, 161, 255);
}

.choose-us-section {
  margin-bottom: 6rem;
}
.about-jumbotron h1 {
  font-size: 3.5rem !important;
  color: whitesmoke;
  font-weight: 800 !important;
}

.about-jumbotron p {
  font-size: 1.25rem !important;
  color: whitesmoke;
  line-height: 1.5; 
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 1rem;/* Matches default line-height */
}


@media (max-width: 768px) {
  .about-jumbotron h1 {
    font-size: 2rem; /* Smaller font size for medium screens */
  }
  
  
 

 
}

@media (max-width: 480px) {
  .about-jumbotron {
    padding: 20px; /* Increase padding for better spacing */
  }

  .about-jumbotron h1{
    font-size: 1.5rem; /* Even smaller font size for small screens */
  }

  


}

.about-container {
  min-height: 100vh;
  background-color: black;
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.6)), url('./bgimg.webp'); /* Overlay added here */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  background-attachment: fixed; /* Fix the background image during scroll on desktop */
  position: relative; /* Required for positioning */
}

@media (max-width: 1350px) {
  .about-container {
    background-attachment: scroll; /* Make background scroll with the page on mobile */
    background-size: cover; /* Keep it covering the entire area */
    background-position: center; /* Ensure it stays centered */
  }
}

.header-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.date-counter {
  margin-top: 20px !important;
}

.about-head1, .about-head {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}



.about-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

@media (max-width: 768px) {
  .about-head1, .about-head {
    font-size: 2rem;
  }
  .about-col {
    text-align: center;
  }
}



.creator-head {
  color: rgb(68, 161, 255);
  font-weight: bold;
}

.creator-bio {
  font-weight: 400;
}

.large-icon {
  font-size: 3rem; /* Adjust the size as needed */
}

.join-team-container {
  background-color: rgba(0, 0, 0, 0.95); /* Adjust to fit your branding */
}

.join-team-container {
  color: #fff; /* White text for contrast */
  text-align: center; /* Center-aligns content */
  padding: 3rem 0; /* Padding for top and bottom */
  margin-top: 6rem; /* Top margin for spacing */
}
.join-team{
  font-weight: normal;
}

/* Button Styling in the CTA Section */
 .btn-primary {
  background-color: #006adb; /* Slightly darker blue for the button */
  border-color: #004085; /* Matching border color */
  font-size: 18px; /* Larger font size for visibility */
  padding: 10px 30px; /* Larger padding for a bigger button */
  transition: background-color 0.3s ease-in-out; /* Transition for a smooth color change on hover */
}

.btn-primary:hover {
  background-color: #003875; /* Darker shade on hover for interaction feedback */
}
.backstory-section, .offers-vision-section {
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  z-index: 2;
  margin-bottom: 2rem !important;
}

.choose-us-head {
  margin-top: 2rem;
  font-weight: bold;
  color: rgb(68, 161, 255);
  font-size: 3rem;
}

.choose-us-bio {
  font-size: 1.3rem !important;
  font-weight: 400;
  margin-bottom: 2rem !important;
  padding: 5px;
}

.offer-content, .vision-content, .choose-us-content, .join-team-content {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #f8f9fa;
}

.backstory-section {
  padding: 100px 0;
  background-color: white;
  color: black;
  position: relative;
  z-index: 0;
}



.black-section {
  background-color: #000;
  color: #fff;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.offers-vision-section {
  background-color: white;
  color: black;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.creator-image-col, .choose-us-col, .join-team-col {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.creator-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid rgb(68, 161, 255);
}

.creator-info-col {
  text-align: left;
}

.creator-head, .choose-us-head, .join-team-head, .section-head {
  margin-bottom: 20px !important;
}

.creator-bio, .choose-us-bio, .join-team-bio, .section-bio {
  font-size: 1.1em;
  line-height: 1.6;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  padding: 0 1rem; /* Add padding to prevent overflow */

  line-height: 1.2; /* Adjusted to reduce space between lines */

}



.offer-icon, .vision-icon {
color: rgb(68, 161, 255) ;
}

.section-head {
  font-size: 2rem;
  font-weight: bold;
}


.section-bio {
  font-size: 1.25rem;
  font-weight: 400;
}

.word {
  font-size: 7vw;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.08em;
  margin: 0;
}

.learn-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  color: white;
  font-size: 1.5rem;
}
.learn-more {
  margin-top: 1rem;
  bottom: 20px; /* Adjust the bottom value as needed */
  width: 100%;
}



.learn-more span {
  margin-top: 10px;
}

.circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: white;
  color: black;
  margin-top: 1rem;
}


.highlight {
  color: rgb(68, 161, 255);
}

.about-head1, .about-head {
  font-size: 3rem;
  font-weight: bold;
}

.sadboi {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

.date-counter {
  text-align: center;
  margin-top: 50px;
}

.about-feature-cards {
  font-weight: bold;
}

.small-text {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
  color: rgb(68, 161, 255);
}

.counter {
  color: black;
  font-size: 2rem;
}

@media (max-width: 992px) {

  .about-jumbotron h1 {
    font-size: 2.5rem !important;
    color: whitesmoke;
    font-weight: 800 !important;
  }

  .about-head {
    margin-top: 1rem;
  }
.word {
  font-size: 10vw;
}

.creator-bio {
  text-align: center;
}

.creator-head {
  text-align: center;
}

  .creator-image {
    margin-bottom: 2rem;
  }
  
  .offer-content {
    margin-bottom: 2rem;
  }



.offer-content {
  margin-bottom: 2rem;
}

}

@media (max-width: 768px) {

  .creator-bio {
    text-align: center;
  }
  
  

  .creator-head {
    text-align: center;
  }
  
    .creator-image {
      margin-bottom: 2rem;
    }
    
    .offer-content {
      margin-bottom: 2rem;
    }
  
  .about-head1, .about-head, .choose-us-head, .section-head {
    font-size: 2rem;
  }
  
  .offer-content {
    margin-bottom: 2rem;
  }
  
  }

  @media (max-width: 550px) {
.counter {
  font-size: 1.5rem !important;
}

  }

  @media (max-width: 600px) {
   
   

    .who-we {
      font-size: 1rem !important;
    }
    
      }

      @media (max-width: 500px) {
        .word {
          font-size: 3rem;
        }
    
       
          }

      @media (max-width: 420px) {
        .word {
          font-size: 2.5rem;
        }
        
          }
.custom-container {
  background-color: black;
  color: white;
  height: auto; /* Allow container to expand with content */
  padding-top: 20px;
  /* Increase bottom padding so form content won’t overlap a footer */
  padding-bottom: 50px;
  overflow: hidden; /* Ensures no unwanted overflow */
}

.contact-detail {
  font-weight: bold;
  font-size: large;
}

.form-header {
  font-weight: bold;
  text-align: left;
}

.left-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 5rem;
  /* Removed negative margin-top as no value was set */
}

.small-text {
  font-size: 1rem; /* Smaller font size for the descriptive text */
}

.contact-info p {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  color: black;
  margin-right: 10px;
  border: solid 2px rgb(95, 175, 255);
  font-size: 1.2rem; /* Larger font size for icons */
}

.contact-text1 {
  font-size: 1rem; /* Smaller font size for the descriptive text */
  text-align: left;
  margin-left: -1rem;
  margin-bottom: 10px;
  margin-top: 0; /* Remove top margin to move higher */
}

.homeform-heading {
  text-align: left;
  font-size: 3.5rem;
  margin-top: 0; /* Remove top margin to move higher */
}

.paper-like-container {
  background-color: whitesmoke;
  color: black;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
  padding: 30px;
  border-radius: 10px;
  width: 85%;
  margin-left: 3rem;
  margin-top: 5rem;
  /* Add a bottom margin so the form doesn’t directly butt against any elements below */
  margin-bottom: 2rem;
}

.paper-like-input {
  background-color: #f3f3f3 !important;
  border: solid 1px rgb(234, 234, 234) !important;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
  padding: 10px;
}

.paper-like-input:focus {
  border-color: rgb(95, 175, 255) !important;
  box-shadow: 0px 0px 4px rgba(0, 123, 255, 0.6);
}

/* Styles for thank-you message */
.thank-you-title {
  padding-bottom: 1rem !important;
  font-weight: 750 !important;
  color: rgb(95, 175, 255)!important;
  font-size: 2.5rem !important;
  text-transform: uppercase;
}

.thank-you-text {
  font-weight: 400 !important;
  font-size: 1.1rem !important;
  color: rgb(0, 0, 0) !important;
}

.response-title {
  font-weight: 600 !important;
  font-size: 1.5rem !important;
  color: rgb(0, 0, 0) !important;
}

.response-list {
  list-style: none !important;
  color: black !important;
  padding: 0 !important;
  text-align: left !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.response-list li {
  margin-bottom: 0.5rem;
  text-align: left;
}

.response-list li::before {
  content: '•';
  margin-right: 0.5rem;
  color: rgb(0, 0, 0);
}

/* Base styles */
.custom-my-5.custom-wwo-section {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 0;
}

.paper-like-input::placeholder {
  color: #9f9f9f;
}

.custom-services-subheader {
  display: flex;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.custom-services-subheader h3 {
  color: #acacac;
  margin: 0;
  font-size: large;
}

.custom-services-subheader .custom-line-icon {
  height: 1px;
  width: 80px;
  background-color: #acacac;
  margin-left: 10px;
}

.centered-form .form-control,
.centered-form .btn {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* Responsive adjustments */
@media (min-width: 2000px) {
  .custom-container {
    min-height: 75vh;
  }
}
@media (max-width: 2000px) {
  .custom-container {
    min-height: 100vh;
  }
}

@media (max-width: 1024px) {
  .custom-container {
    min-height: 70vh !important;
  }
  .right-column {
    text-align: center;
    width: 100%;
  }
  .contact-text1 {
    margin-top: 1rem;
  }
  .paper-like-container {
    width: 90% !important;
    margin-top: 2.5rem !important;
    margin-left: auto;
    margin-right: auto;
  }
  .right-column {
    margin-top: 20px;
  }
}

@media (max-width: 992px) {
  .custom-container {
    min-height: 90vh !important;
  }
}

@media (max-width: 768px) {
  /* Ensure columns are centered and remove left padding from the left column */
  .left-column,
  .right-column {
    text-align: center;
    width: 90%;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
  }
  
  .paper-like-container {
    width: 100% !important;
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  
  .contact-text1 {
    font-size: 1.2rem;
    margin-left: 0rem;
    margin-bottom: 10px;
    margin-top: 1rem;
  }
  
  .homeform-heading {
    font-size: 2rem;
    margin-top: 1.5rem;
  }
}

@media (max-width: 500px) {
  .email-detail {
    display: none !important;
  }
  .contact-text1 {
    text-align: center;
  }
  .homeform-heading {
    text-align: center;
  }
  .thank-you-title {
    padding-bottom: 0.5rem !important;
    font-weight: 750 !important;
    color: rgb(95, 175, 255)!important;
    font-size: 2rem !important;
    text-transform: uppercase;
  }
  .thank-you-text {
    font-weight: 400 !important;
    font-size: medium !important;
    color: rgb(0, 0, 0) !important;
  }
  .response-title {
    font-weight: 600 !important;
    font-size: 1.3rem !important;
    color: rgb(0, 0, 0) !important;
  }
  .response-list {
    list-style: none !important;
    color: black !important;
    padding: 0 !important;
    text-align: left !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    font-size: medium !important;
  }
}

@media (max-width: 400px) {
  .left-column {
    display: none;
  }
  
  .thank-you-title {
    padding-bottom: 0rem !important;
    font-weight: 750 !important;
    color: rgb(95, 175, 255) !important;
    font-size: x-large !important;
    text-transform: uppercase;
  }
  
  .thank-you-text {
    font-weight: 400 !important;
    font-size: small !important;
    color: rgb(0, 0, 0) !important;
    margin-bottom: 10px !important;
  }
  
  .response-title {
    font-weight: 600 !important;
    font-size: large !important;
    color: rgb(0, 0, 0) !important;
  }
  
  .response-list {
    list-style: none !important;
    color: black !important;
    padding: 0 !important;
    text-align: left !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    font-size: small !important;
    margin-bottom: -1rem !important;
  }
  
  .homeform-heading {
    text-align: center;
    font-size: x-large;
    margin-bottom: -1rem;
  }
  
  .contact-text1 {
    font-size: large;
  }
  
  .centered-form {
    margin-bottom: 0rem !important;
  }
  
  .paper-like-input {
    font-size: small !important;
  }
}

/* Larger screens */
@media (min-width: 1024px) {
  .custom-container {
    background-color: black;
    color: white;
    /* The following height values are intentionally applied based on your design */
    padding-top: 20px;
    padding-bottom: 50px; /* Ensure enough space so content does not crowd the footer */
    height: 50vh;
  }
}

@media (min-width: 1300px) {
  .custom-container {
    background-color: black;
    color: white;
    padding-top: 20px;
    padding-bottom: 50px;
    height: 50vh;
  }
}

@media (min-width: 1700px) {
  .custom-container {
    background-color: black;
    color: white;
    height: 50vh;
    padding-top: 20px;
    padding-bottom: 50px;
  }
}

.contact-modal-header {
    background-color: black;
    border: none;
  }
  
  .contact-modal-title {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .contact-modal-body {
    background-color: white;
    padding: 2rem;
    text-align: center !important;
  }
  
  .contact-form-group {
    margin-bottom: 1.5rem;
  }
  
  .contact-form-control {
    border: none;
    border-bottom: 2px solid rgb(234, 234, 234);
    border-radius: 0;
    padding: 0.75rem;
    font-size: 1rem;
    color: black;
  }
  
  .contact-form-control:focus {
    box-shadow: none;
    border-color: rgb(116, 185, 255);
  }

  @media (max-width: 768px) {
  .pricing-table-title {
    margin-bottom: 1rem;
}
  }
  
  @media (max-width: 500px) {
    .modal-dialog {
      margin: 0 !important;
      max-width: 100% !important;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
    .contact-modal-title {
      font-size: large;
    }
  
    .modal-content {
      height: 100%;
      border-radius: 0;
    }
  
    .contact-modal-body {
      padding: 1rem;
      overflow-y: auto;
    }
  
    .price-name {
      margin-top: 2rem;
    }
  
    .modal-header {
      border-bottom: none;
    }
    .pricing-table-title {
        margin-bottom: 1rem;
    }
  }
  
  .contact-submit-btn {
    background-color: rgb(58, 157, 255);
    border: none;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .contact-submit-btn:hover {
    background-color: rgb(0, 60, 119);
    color: white;
  }
  
  .selected-plan-details {
    background-color: #f8f9fa;
    padding: 1.5rem;
    border-radius: 5px;
  }
  
  .selected-plan-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .selected-plan-cost {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  .selected-plan-features {
    list-style-type: none;
    padding-left: 0;
  }
  
  .selected-plan-features li {
    margin-bottom: 0.5rem;
  }
  
  /* New styles for white close button */
  .contact-modal-header .btn-close {
    filter: invert(1);
    color: white !important;
  }
  
  .submit-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }
  
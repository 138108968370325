.divine-feature-card {
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure all cards have the same height */
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: 0.25rem;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s ease; /* Smooth transition for transform changes */
}

.divine-feature-card:hover {
    transform: scale(1.05); /* Scales up the card by 5% on hover */
}

.divine-feature-card .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.divine-feature-icon {
    font-size: 2rem; /* Larger icon size */
    color: rgb(22, 139, 255) ; /* Bootstrap primary color */
}

.divine-feature-card .card-title {
    margin-bottom: 0.75rem;
}

.divine-feature-card .card-text {
    flex-grow: 1;
}

/* Ensure the modal is wider */
.wide-modal .modal-dialog {
    max-width: 80%;
  }
  
 
  /* Styling for the 'See All' button */
  .see-all-btn {
    background-color: rgb(68, 161, 255);
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-weight: 600;
    margin-top: -3rem;
    margin-bottom: 2rem;

  }
  
  .see-all-btn:hover {
    background-color: rgb(0, 98, 197);
  }
  
  /* Style for the blog card button */
  .blog-card-btn {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-weight: bold;
    background: #d9efff;
    color: #333333;
    cursor: pointer;
    border: none;
  }
  
  .blog-card-btn:hover {
    background-color: rgb(68, 161, 255);
  }
  
  .blog-section-container {
    padding: 2rem 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .blog-card {
    text-align: left;
    width: 100%;
    background: #fff;
    border-radius: 1.5rem;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease;
    border: 1px solid rgb(68, 161, 255);
    overflow: hidden;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
  }
  
  .blog-card-img-holder {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
  
  .blog-card-img-holder img {
    width: 100%;
    height: 15rem; /* Set a fixed height */
    object-fit: cover; /* Ensures images maintain aspect ratio and fill the space */
    border-radius: 1.5rem;
}
  
  .blog-card-title {
    color: #22215B;
    padding: 0.5rem 0;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0rem;
  }

  .blog-modal-title {
    font-weight: bold;
  }
  
  .blog-card-description {
    padding: 0.5rem 0;
    color: #22215B80;
    font-size: 1rem;
  }
  
  .blog-card-time {
    font-size: 1rem;
    color: #22215B;
    margin-bottom: 0.5rem;
  }
  
  .blog-card-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .blog-card-options span {
    font-weight: 600;
    color: #22215B;
    cursor: pointer;
  }
  
  .blog-header {
    margin-bottom: 2rem;
  }
  
  .blog-subtitle {
    font-size: 1.25rem;
    color: #555;
  }
  
.border-bottom {
    max-width: 100% !important;

}

.new-add {
padding-bottom: 1rem;
}

.divine-para p {
    font-weight: normal;
}
/* General Styles */
.divine-pricing-title {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 2rem;
}

.scrollable-features {
  max-height: 300px; /* Adjust the height as needed */
  overflow-y: auto;
}

.what-you {
  padding-bottom: 1rem;
}

.divine-pricing-card {
  transition: transform 0.3s ease-in-out;
  width: 85%; /* Adjust based on preference */
  margin: 0 auto; /* Centers the card */
}

.divine-pricing-card:hover {
  transform: scale(1.05);
  z-index: 1; 
}

/* Plan Details */
.divine-plan-detail {
  font-size: 0.9rem; /* Smaller font for details */
}

.divine-price-highlight {
  font-size: x-large; /* Larger font for price */
  font-weight: 800;
}

.divine-billing-info {
  font-size: 1rem;
  text-align: left !important;
  font-weight: 500;
}

.divine-price-card {
  background-color: #f8f8f8 !important; /* Light grey background for contrast */
  border: none;
  box-shadow: 0 4px 8px rgba(0,0,0,0.5); /* Subtle shadow for depth */
}

.divine-feature-list li {
  font-size: 1rem;
  padding-left: 20px; /* Adjusts bullet points */
  text-align: left;
}

/* Card Header */
.divine-card-header {
  height: 5rem;
  padding-top: 1.5rem;
  font-size: 1.8rem !important;
  font-weight: 750 !important;
}

.divine-favorite-plan .divine-card-header {
  background-color: rgb(97, 176, 255) !important; 
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  /* Highlight for Enterprise Plan */
}


.divine-pricing-card, .divine-price-card {
  border-radius: 20px;
}

.divine-card-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}


/* Button Styles */
.divine-pricing-card .btn-primary {
  background-color: rgb(154, 154, 154); /* Blue background */
  border-color: rgb(185, 185, 185); /* Matching border */
}

.divine-pricing-card .btn-primary:hover {
  background-color: rgb(94, 94, 94); /* Darker on hover */
  border-color: rgb(94, 94, 94);
}

.divine-favorite-plan .btn {
  background-color: rgb(97, 176, 255) !important;
  border-color: rgb(97, 176, 255);
}

.divine-favorite-plan .btn:hover {
  background-color: rgb(0, 99, 199) !important;
  border-color: rgb(0, 99, 199)   !important;
}

.divine-favorite-plan .divine-pricing-card {
  transform: scale(1.1); /* Enlarge the favorite plan */
}

.divine-card-header {
  background-color: rgba(185, 185, 185, 0.1) !important; 
  border: none;/* Adjust the alpha value as needed */
}

/* Card Body */
.divine-pricing-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Adjusts space */
}

.text-success {
color: rgb(97, 97, 97) !important; 
}

.price-marg {
  margin-top: 5rem;
}

/* Adjustments for screen sizes below 1200px */
@media (max-width: 1200px) {
  .divine-pricing-card {
      width: 100%; /* Make the cards full width */
      margin-bottom: 4rem; /* Add spacing between cards */
  }
}


@media (max-width: 1400px) {

   
    /* Adjust text sizes for better readability on smaller screens */
    .divine-card-header {
      font-size: x-large !important; /* Adjust the font size if needed */
    }
  
}

@media (max-width: 992px) {

   
  /* Adjust text sizes for better readability on smaller screens */
  .divine-card-header {
    font-size: xx-large !important; /* Adjust the font size if needed */
  }

}

@media (max-width: 768px) {
  .divine-pricing-card {
      width: 90%; /* Make the cards full width */
      margin-bottom: 4rem !important; /* Add spacing between cards */
  }
}

@media (max-width: 468px) {
  .divine-pricing-card {
      width: 90%; /* Make the cards full width */
      margin-bottom: 4rem !important; /* Add spacing between cards */
  }
}

@media (max-width: 400px) {
  .divine-card-header {
    font-size: x-large !important; /* Adjust the font size if needed */
  }
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1030; /* Ensures the navbar is on top */
  transition: background-color 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out; /* Smooth transition for background color and blur effect */
}


.phone-icon:hover {
  text-decoration: none !important; /* Ensure no underline */
  color: white !important; /* Ensure color stays the same on hover */
}

@media (min-width: 992px) {
  .dropdown-menu .dropdown-item {
    padding-top: 10px; /* Adjust as needed */
    padding-bottom: 10px; /* Adjust as needed */
    text-align: left;
  }
}




.phone-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
}

.phone-icon svg {
  width: 20px;
  height: 20px;
}

@media (max-width: 992px) {
  .phone-icon {
    align-items: left;
    justify-content: left;
    
  }

  .navbar {
    position: absolute;
  }
}


@media (min-width: 992px) {
  .phone-icon {
    width: 40px;
    height: 40px;
    background-color: #007bff;
    border-radius: 50%;
    color: white;
    margin-left: 5px;
  }

  
}





@media (max-width: 991px) {
  .phone-icon {
    display: inline-flex;
    align-items: center;
  }
  .phone-icon span {
    display: inline;
    margin-left: 10px;
  }
}




@media (min-width: 992px) {
  .nav-item:hover .dropdown-menu {
    display: block; /* Display dropdown menu on hover */
  }
  .phone-dropdown{
    display: none; /* Hide the dropdown menu by default */
    position: absolute;
    top: 50%; /* Align vertically with the phone icon */
    left: -110px; /* Adjust this value as needed to position the dropdown to the left */
    transform: translateY(40%);
    color: #007bff;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 1050;
    white-space: nowrap; /* Prevent text wrapping */
  }
  
}

.navbar-top {
  background-color: transparent !important; /* Transparent background at the top */
}

.navbar-scrolled {
  background-color: rgba(0, 0, 0, 0.9) !important; /* Light black opacity on scroll */
  backdrop-filter: blur(10px); /* Apply blur effect */
}





/* As navbar is always black now, these styles are applied universally */
.navbar .navbar-brand,
.navbar .nav-link,
.navbar .logo-text {
  color: white !important; /* White text for visibility against the black background */
}


.nav-link.active {
  border-bottom: 2px solid rgb(68, 161, 255)!important;
}

@media (max-width: 992px) {
  .dropdown-item.active {
    background-color: black !important; 
    font-weight: 500 !important;
    color: rgb(116, 185, 255) !important; /* Change to desired active color */
  border-bottom: 2px solid rgb(68, 161, 255) !important;/* Underline the active link */
  }

  .contact-nav-btn {
    margin-top: 1.5rem;
  }

}


/* Navbar brand styling */
.navbar-brand {
  font-size: 1.5rem !important;
  font-weight: 800 !important;
}

/* Navbar link styling */
.nav-link {
  font-size: 1.2rem;
  font-weight: 500;
  margin-right: 10px;
  margin-top: .3rem;
  letter-spacing: 1px;
  transition: color 0.2s ease-in-out;
  position: relative; /* Needed to position the underline */
}

.call-now-icon {
  margin-left: .2rem;
}

.contact-nav-btn {
  padding: 8.5px 15px !important;
  border-radius: 10px;
margin-left: .5rem;
color: white;
font-weight: bold;
letter-spacing: .5px;
font-size: 1.15rem !important;

}



/* Create the pseudo-element for the underline only for non-dropdown links */
.nav-link:not(.dropdown-toggle)::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust as needed to position the underline */
  width: 0;
  height: 2px;
  background-color: rgb(68, 161, 255); /* Underline color */
  transition: width 0.3s ease-in-out;
}

/* Navbar link hover effect */
.nav-link:not(.dropdown-toggle):hover::after {
  width: 100%; /* Expand underline to full width on hover */
}

/* Regular hover effect for dropdown items */
.dropdown-item:hover {
  color: rgb(255, 255, 255) !important; /* Blue hover effect for items */
  text-decoration: underline; /* Underline effect on hover */
  background-color: rgb(23, 139, 255); /* Keeps background transparent on hover */
}

/* Navbar link hover effect */
.nav-link:hover {
  border-bottom-color: rgb(68, 161, 255) !important; /* Hover effect */
}


.logo-text {
color: rgb(68, 161, 255)!important; /* Sets the logo text color */
font-size: 1.4rem; /* Adjusted font size */
font-weight: 750; /* Adjusted font weight */
padding-left: .7rem; /* Adjust padding as needed */
}


/* Navbar dropdown styling */
.dropdown-menu {
  background-color: #000 !important; /* Consistently black background for dropdown menu */
  animation: fadeInDown 0.5s ease; /* Smooth animation for dropdown */
}

.dropdown-item {
  color: #fff !important; 
  font-weight: 500 !important;
  letter-spacing: .3px;
  font-size: 1.15rem;
text-decoration: none !important;
}




/* This will center the navbar items when the navbar is expanded */
/* Directly target the div containing the navbar-nav */
.navbar-collapse.collapse.show {
  justify-content: center !important;
}

/* Center text within dropdown menus */
.dropdown-menu {
  text-align: center; /* Center the text of dropdown items */
}

/* Optional: Ensure dropdown items take full width for better centering effect */
.dropdown-menu .dropdown-item {
  display: block;
}






/* Adjustments for smaller screens */
@media (max-width: 992px) {
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-top {
  background-color: black !important; /* Black background at the top */
}

.navbar-scrolled {
  backdrop-filter: none;
  background-color: black !important; /* Remove blur effect on smaller screens */
}

.navbar-top {
  background-color: black !important; /* Transparent background at the top */
}
}

@media (max-width: 768px) { /* Adjust for tablets */
  .logo-text {
    font-size: 1.2rem; /* Smaller font size on tablets */
  }
    
}

@media (max-width: 480px) { /* Adjust for mobile phones */
  .logo-text {
    font-size: large; /* Even smaller font size on mobile phones */
  }
  .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
    
}

.navbar-brand img {
  max-height: 3rem !important; /* Set a max height */
  width: auto; /* Maintain aspect ratio */
  height: auto; 
}

/* Adjust logo size on smaller screens */
@media (max-width: 768px) {
  .navbar-brand img {
    max-height: 50px; /* Smaller logo on tablets */
  }
}

@media (max-width: 480px) {
  .navbar-brand img {
    max-height: 40px; /* Even smaller logo on mobile phones */
  }
}


@media (max-width: 992px) { /* Adjust this breakpoint as needed */
  .navbar-nav {
    justify-content: center !important;
    flex-direction: column; /* Ensures items are stacked vertically */
  }

  .navbar-brand img {
    padding-left: 0px;
  }

  
  
  .nav-link {
    text-align: left; 
    border-bottom: 1px solid gray; /* Adding grey border to the bottom of each item */
    /* Centers the text of nav links */
    width: 100%;
    padding: 20px; 
    font-weight: 500 !important;
    font-size: 1.25rem !important;/* Ensures nav links span the full width of the navbar */
  }
  
  
}

@media (min-width: 1700px) {
  /* Increase padding and margin for larger screens to better use space */
  .navbar .nav-link {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.2rem; /* Slightly larger font for better readability */
  }

  .navbar-brand {
    font-size: 2rem; /* Larger font size for brand for better visibility */
    padding-left: 2rem; /* More space on the left */
  }

  .logo-text {
    font-size: 1.6rem; /* Larger text size for logo text */
  }


  /* Dropdown menu width and text alignment for better layout on large screens */
  .dropdown-menu {
    min-width: 100%; /* Ensuring a minimal width for dropdowns */
    padding: 10px 20px; /* Better padding for aesthetics */
  }

  /* Center align the dropdown menu items text for better readability */
  .dropdown-menu .dropdown-item {
    text-align: left; /* Aligning text to left for consistency */
  }

  /* Hover effects for dropdown items to enhance visibility and interactivity */
 

  /* Ensure navigation items are visually centered */
  .navbar-collapse.collapse.show {
    justify-content: flex-end; /* Align items to the right */
  }

  /* Center logo and navbar items vertically */
  .navbar {
    align-items: center;
  }
}

@media (max-width: 400px) {

  .logo-text {
    font-size: small; /* Adjusted font size */
    
    }
    .call-now {
      font-size: medium;
      
    }

  
}

/* Slide down animation */
@media (max-width: 992px) {
  @keyframes slideLeftToRight {
  from {
    transform: translateX(-100%);  /* Start from off the right edge of the screen */
  }
  to {
    transform: translateX(0);     /* End at the original position */
  }
}


.navbar-collapse.collapsing {
  display: block;
  transition: width 0.6s ease;  /* Transition the width for horizontal movement */
  width: 0;  /* Start from zero width to extend from right to left */
  height: auto !important;  /* Ensure the height is auto to cover items properly */
  background-color: black;  /* Set background color to black */
}

.navbar-collapse.show {
  position: absolute;  /* Ensure it does not disrupt other layout */
  width: 100%;  /* Full width as it slides in */
  right: 0;  /* Align to the right edge */
  top: 100%;  /* Start immediately below the navbar */
  animation: slideLeftToRight 0.6s ease forwards;
  background-color: black; 
  height: 100vh;  /* Set the height to 100% of the viewport */

  
   /* Ensure background color remains black during animation */
}

.navbar-collapse ul {
  padding-left: 20px;  /* Adds padding to align text more to the left */
  text-align: left !important; 
   /* Aligns text to the left */
}
.dropdown-menu .dropdown-item {
  text-align: left !important;
  padding: 10px; 
  font-size: 1.2rem !important; /* Aligning text to left for consistency */
  border-bottom: 1px solid lightgray; /* Adding grey border to the bottom of each item */
}
}


@media (min-width: 990px) and (max-width: 1100px) {
  /* Add your CSS styles here */
  .dropdown-menu .dropdown-item {
      font-size: medium; /* Example: light background for the navbar */
  }
  .nav-link {
      font-size: medium; /* Example: increased padding for a class */
  }
}

@media (max-width: 992px) {
  @keyframes slideDown {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 500px; /* Adjust this value as needed */
      opacity: 1;
    }
  }

  @keyframes slideUp {
    from {
      max-height: 500px; /* Adjust this value as needed */
      opacity: 1;
    }
    to {
      max-height: 0;
      opacity: 0;
    }
  }

  .custom-dropdown {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease, opacity 0.4s ease;
  }

  .custom-dropdown.show {
    max-height: 500px; /* Adjust this value as needed */
    opacity: 1;
    animation: slideDown 0.4s ease forwards;
  }

  .custom-dropdown:not(.show) {
    animation: slideUp 0.4s ease forwards;
  }
}



.selected-plan-details {
  text-align: center;
}

.selected-plan-features {
  list-style: none;
  padding-left: 0;
  text-align: left;
}

.selected-plan-feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.selected-plan-feature-item .fa-check-circle {
  margin-right: 0.5rem;
}

.blur-background {
  filter: blur(10px);
  transition: filter 0.3s ease-in-out;
  z-index: 2000; /* Higher than the navbar */
}

.pricing-plan-features-container {
  display: flex;
  justify-content: center;
  padding: 0;
}

.icon-pricing {
  margin-right: 1rem;
  color: rgb(116, 185, 255) !important;
}

.pricing-plan-features {
  list-style: none;
  padding-left: 0;
  text-align: left;
}

.pricing-plan-feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.pricing-plan-feature-item .fa-check-circle {
  margin-right: 0.5rem;
}

/* Add a class to blur the navbar when the modal is open */
.navbar.blur {
  filter: blur(10px);
  transition: filter 0.3s ease-in-out;
  z-index: 999; /* Adjust to ensure it is lower than the blur background */
}

body.modal-open {
  overflow: hidden; /* Prevent scrolling when modal is open */
}

.get-a-quote-section {
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid black;
}

body {
  font-family: 'Karla', sans-serif;
}

.pricing-table-title {
  font-weight: bold;
  margin-bottom: 50px;
}

.pricing-card {
  border: none;
  border-radius: 10px;
  margin-bottom: 40px;
  text-align: center;
  transition: all 0.6s;
}

.pricing-card:hover {
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
}

.pricing-card.pricing-card-highlighted {
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
}

.pricing-card:hover {
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.55);
  transform: translateY(-10px);
}

.pricing-card .card-body {
  padding-top: 55px;
  padding-bottom: 62px;
}

.pricing-plan-title {
  font-size: 25px;
  color: #000;
  margin-bottom: 11px;
  font-weight: normal;
}

.pricing-plan-cost {
  font-size: 40px;
  color: #000;
  font-weight: bold;
  margin-bottom: 29px;
}

.pricing-plan-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 40px;
  line-height: 1;
  margin-bottom: 24px;
  color: rgb(116, 185, 255);
}

.pricing-plan-basic .pricing-plan-icon {
  color: rgb(116, 185, 255);
}

.pricing-plan-pro .pricing-plan-icon {
  color: rgb(116, 185, 255);
}

.pricing-plan-enterprise .pricing-plan-icon {
  color: rgb(116, 185, 255);
}

.pricing-plan-features {
  list-style: none;
  padding-left: 0;
  font-size: 17px;
  line-height: 2.14;
  margin-bottom: 35px;
  color: #303132;
}

.pricing-plan-purchase-btn {
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  width: 145px;
  height: 45px;
  border-radius: 22.5px;
  transition: all 0.4s;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 1.5rem;
  border-color: transparent;
  background-color: rgb(65, 160, 255);
}

.pricing-plan-purchase-btn:hover {
  background-color: rgb(0, 60, 119) !important;
  border-color: transparent;
}

.pricing-plan-basic .pricing-plan-purchase-btn {
  background-color: rgb(65, 160, 255);
  color: #fff;
}

.pricing-plan-basic .pricing-plan-purchase-btn:hover {
  box-shadow: 0 3px 0 0 rgb(0, 60, 119);
}

.pricing-plan-basic .pricing-plan-purchase-btn:active {
  transform: translateY(3px);
  box-shadow: none;
}

.pricing-plan-pro .pricing-plan-purchase-btn {
  background-color: rgb(65, 160, 255);
  color: #fff;
}

.pricing-plan-pro .pricing-plan-purchase-btn:hover {
  box-shadow: 0 3px 0 0 rgb(0, 60, 119);
}

.pricing-plan-pro .pricing-plan-purchase-btn:active {
  transform: translateY(3px);
  box-shadow: none;
}

.pricing-plan-enterprise .pricing-plan-purchase-btn {
  background-color: rgb(65, 160, 255);
  color: #fff;
}

.pricing-plan-enterprise .pricing-plan-purchase-btn:hover {
  box-shadow: 0 3px 0 0 rgb(0, 60, 119);
}

.pricing-plan-enterprise .pricing-plan-purchase-btn:active {
  transform: translateY(3px);
  box-shadow: none;
}

.snackbar {
  visibility: visible;
  width: 100%;
  background-color: rgb(65, 160, 255);
  color: #fff;
  text-align: center;
  border-radius: 0;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 0;
  font-size: 20px;
}

.get-quote h2 {
  font-weight: 900;
  font-size: 2rem;
  color: rgb(116, 185, 255);
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.price-web {
  font-size: 1.2rem !important;
}

@media (max-width: 578px) {
  .radio-web {
    margin-top: -.5rem;
  }

  .web-mob {
    margin-top: -.5rem;
  }

  .web-mob1 {
    margin-top: .5rem;
  }
}

.blur-background {
  filter: blur(10px);
  transition: filter 0.3s ease-in-out;
}



@media (max-width: 768px) {
  .pricing-plan-cost {
    font-size: 40px;
  }

  .pricing-table-title {
    margin-bottom: 0rem;
  }
}

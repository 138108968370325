/* Contact.css */
/* Existing styles */
.jumbotron {
    background-color: black;
    margin-bottom: 20px;
    padding: 10px 0;
    color: white;
    min-height: 35vh;
    padding-left: 10rem;
    padding-right: 10rem;
}

.radio-buttons{
    text-align: left;
}




.contact-form-container {
    max-width: 900px; /* Increased width for a more spacious form */
    margin: auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0,0,0,0.3) !important;
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
}

.form-control:focus {
    border-color: rgb(116, 185, 255);
    box-shadow: 0 0 0 0.25rem rgba(116, 185, 255, 0.25); /* Optional: Adds a glow effect */
}

/* For custom radio buttons if needed */
.form-check-input:focus {
    border-color: rgb(116, 185, 255);
    box-shadow: 0 0 0 0.25rem rgba(116, 185, 255, 0.25);
}

.section-header {
    text-align: left;
    font-size: 28px;
    color: rgb(0, 0, 0);
    font-weight: 800;
    margin-bottom: 20px;
    letter-spacing: 2px;
}

.form-label {
    text-align: left; /* Ensure labels are left-aligned */
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.custom-radio-buttons .form-check-label {
    text-align: left; /* Align radio labels to the left */
}

/* Styling for radio buttons to align text and make it engaging */
.custom-radio-buttons .form-check {
    margin-bottom: .5rem; /* Space between radio buttons */
}

.custom-radio-buttons .form-check-input {
    margin-top: 0.3rem;
}

.custom-radio-buttons {
    display: flex;
    flex-direction: column;
    align-items:flex-start; }

/* Enhancements for button to make it stand out */
.btnContact {
    background-color: #007bff; /* Primary button color */
    border-color: #007bff;
    padding: 10px 15px;
    font-size: 18px; /* Larger font for button text */
}

.contact-form-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}



.message-section {
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.btnContact {
    background-color: #007bff;
    border-color: #007bff;
}

.custom-radio-buttons .form-check {
    margin-bottom: 10px;
}


/* Additional responsive design tweaks */
@media (max-width: 768px) {
    .custom-radio-buttons .form-check-label {
        margin-left: 5px; /* Adjust for smaller screens */
    }
}



.jumbotron h1 {
    font-size: 3rem; /* Default font size for large screens */
    margin-top: 0;
    padding-top: 2rem;
}

.jumbotron p {
    font-size: 1.2rem; /* Default paragraph font size */
}

.message-textarea {
    width: 100%;
    height: 205px !important; /* This might need adjustment */
    
        resize: vertical; /* Allows only vertical resizing */
    
}


.contact-form form {
    padding: 10%;
}

.contact-form form .row {
    margin-bottom: -7%;
}

.contact-form h3 {
    margin-top: -13%;
    text-align: center;
    color: black;
    font-weight: bolder;
    font-size: x-large;
}

.contact-form .btnContact, .btnContactSubmit {
    width: 50%;
    border: none;
    border-radius: 1rem;
    padding: 1.5%;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}


.btnContact {
    font-size: small;
    /* Smaller font size */
}
.bold-button {
    font-weight: 750 !important;
  }
  

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .jumbotron, .black-container, .contact-form form {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .jumbotron {
        min-height: auto;
        padding-top: 2rem;
    }
    .jumbotron h1, .contact-header {
        font-size: 2.7rem; /* Smaller font size for medium screens */
    }
    .jumbotron p {
        font-size: 1.1rem; /* Adjust paragraph font size for readability */
    }
    
    .contact-form .btnContact, .btnContactSubmit {
        width: 100%; /* Full width for the button on smaller screens */
        padding: 10px; /* Adjust padding */
    }
    .contact-form form {
        padding: 5%; /* Less padding on smaller screens */
    }
    .contact-form h3 {
        font-size: large;
        padding-top: 1rem;
    }
   
}

/* Additional responsiveness for very small screens */
@media (max-width: 500px) {
    .jumbotron h1, .contact-header {
        font-size: 2.3rem; /* Even smaller font size for small screens */
    }
    .jumbotron p {
        font-size: .9rem; /* Smaller paragraph font size */
    }
   
    .contact-form h3 {
        font-size: larger;
    }
    
    
}

/* Increase the maximum width of the form container for larger screens */
@media (min-width: 992px) {
    .contact-form {
        max-width: 80%; /* Adjust this value as needed */
        margin: auto; /* This centers the form container */
    }


/* If you need to adjust the "Your Message" textarea specifically */
.message-textarea {
    width: 100%; /* Ensures it takes full width of its parent, adjust if needed */
    height: 200px; /* Increase height for a larger text area */
}

}

@media (max-width: 700px) {
    .contact-form {
        max-width: 80%; /* Adjust this value as needed */
        margin: auto; /* This centers the form container */
    }


/* If you need to adjust the "Your Message" textarea specifically */
.message-textarea {
    width: 100%; /* Ensures it takes full width of its parent, adjust if needed */
    height: 200px; /* Increase height for a larger text area */
}


}

.contact-page-message {
    margin-top: 0rem;
}






.header-bottom-margin {
  margin-bottom: 2rem;
}

.explore-head {
  padding-top: 1rem !important;
}

/* Recent Work Section */
.work-bg {
  background-color: rgb(246, 246, 246);
  margin-bottom: 2rem;
  padding-top: 6rem !important;
}

.full-width {
  width: 100vw !important; /* Ensure full viewport width */
  padding: 0;
  overflow: hidden; /* Hide any overflow */
}

.image-gallery-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0; /* Reduced vertical padding */
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: calc(100vw + 50px); /* Ensure it extends beyond the viewport */
  margin-left: -50px; /* Offset to allow overflow on the left side */
}

.image-gallery-container::-webkit-scrollbar {
  display: none;
}

.image-container {
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  overflow: hidden;
  margin-right: 10px; /* Added margin to make images closer together */
}

.gallery-image {
  width: 500px; /* Adjusted width to make images smaller */
  height: auto;
  object-fit: cover;
}

@media (max-width: 992px) {
  .image-gallery-container {
    white-space: nowrap; /* Ensure horizontal scrolling */
    padding: 10px 0;
  }

  .image-container {
    display: inline-block; /* Inline for horizontal scrolling */
    margin: 0 5px; /* Small horizontal margin */
    border-radius: 8px;
  }

  .gallery-image {
    width: 400px; /* Smaller width for mobile */
    height: auto;
    object-fit: cover;
  }

  .custom-services-subheader {
    text-align: center;
    margin-bottom: 1rem;
  }

  .custom-services-subheader h1 {
    font-size: 2.2rem !important;
    margin-bottom: 0.5rem;
  }

  .custom-services-subheader p {
    font-size: 1rem !important;
    color: #666;
    margin: 0 auto;
    max-width: 90%;
  }
}

@media (max-width: 600px) {
  .image-gallery-container {
    white-space: nowrap; /* Ensure horizontal scrolling */
    padding: 10px 0;
  }

  .custom-services-subheader h1 {
    font-size: xx-large !important;
  }

  .custom-services-subheader p {
    display: none;
  }

  .image-container {
    display: inline-block; /* Inline for horizontal scrolling */
    margin: 0 5px; /* Small horizontal margin */
    border-radius: 8px;
  }

  .gallery-image {
    width: 350px; /* Smaller width for smaller devices */
    height: auto;
    object-fit: cover;
  }
}

.custom-services-subheader {
  text-align: center;
  margin-bottom: 1.5rem;
}

.custom-services-subheader h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: rgb(116, 185, 255);
}

.custom-services-subheader p {
  font-size: 1.1rem;
  color: #2d2d2d;
  font-weight: 350;
}

.accordion-body {
  text-align: left;
}

.feature-accordion .accordion-item {
  border: none;
  margin-bottom: 15px;
}

.carousel-section {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
}



.carousel-card-content p {
  color: white;
  margin: 5px 0;
  text-align: justify;
  hyphens: auto;
  word-wrap: break-word;
}

.carousel-background-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 0;
  overflow: hidden;
}

.scrolling-text {
  white-space: nowrap;
  font-size: 30rem;
  font-weight: bold;
  color: transparent; /* Make the inside transparent */
  -webkit-text-stroke: 2px rgba(255, 255, 255, 0.08); /* Border color */
  animation: scroll-text 100s linear infinite;
}

@keyframes scroll-text {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.tech-can {
  width: 100% !important;
  padding: 0;
  margin: 0 auto;
  z-index: 1;
}

.carousel-header {
  text-align: center;
  margin-bottom: 20px;
  z-index: 1;
}

.carousel-header h2 {
  color: rgb(116, 185, 255);
  font-size: 4rem;
  font-weight: bold;
}

.carousel-header p {
  color: lightgray;
  font-size: 1.3rem !important;
  padding-bottom: 2rem !important;
}

.carousel-card {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.carousel-card-content {
  border: 1px solid rgb(116, 185, 255);
  background-color: black;
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: 330px;
  height: 430px;
}

.carousel-card:hover .carousel-card-content {
  background: linear-gradient(45deg, #000000cb, #1e8fffc2) !important;
}

.scrollable-section {
  margin-top: -4.5rem !important;
}

.carousel-card-content h3 {
  color: rgb(45, 150, 255);
  margin-top: 10px;
  margin-bottom: 3rem;
  font-size: xx-large;
  font-weight: bold;
}

@media (max-width: 1700px) {
  .carousel-header h2 {
    font-size: 3rem;
  }

  .carousel-section {
    height: 85vh;
  }

  .carousel-header p {
    font-size: large;
  }

  .carousel-card-content {
    width: 320px;
    height: 370px;
  }

  .carousel-card-content h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .carousel-card-content p {
    font-size: 1rem;
  }
}

@media (max-width: 1400px) {
  .carousel-header h2 {
    font-size: 3rem;
  }

  .carousel-header p {
    font-size: large;
  }

  .carousel-card-content {
    width: 320px;
  }

  .carousel-card-content h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .carousel-card-content p {
    font-size: 1rem;
  }
}

@media (max-width: 1200px) {
  .carousel-header h2 {
    font-size: 3rem;
  }

  .carousel-header p {
    font-size: 1.25rem;
  }

  .new-help {
    margin: 0;
    padding: 0;
  }

  .web-head {
    line-height: normal;
  }

  .image-gallery-container {
    overflow-x: auto;
    white-space: nowrap;
    padding: 20px;
  }
}

@media (max-width: 992px) {
  .carousel-header h2 {
    font-size: 2.5rem;
  }

  .carousel-header p {
    font-size: 1.125rem;
  }

  .carousel-card-content {
    width: 350px;
  }

  .carousel-section {
    height: 70vh;
  }

  .carousel-card-content h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .carousel-card-content p {
    font-size: 1rem;
  }

  .feature-accordion {
    margin-top: 0rem !important;
  }
}

@media (max-width: 800px) {
  .carousel-header h2 {
    font-size: 2rem;
  }

  .carousel-section {
    height: 80vh;
  }

  .carousel-header p {
    font-size: 1rem;
  }

  .carousel-card-content {
    width: 250px;
    height: 400px;
  }
}

@media (max-width: 700px) {
  .carousel-header h2 {
    font-size: 2rem;
  }

  .carousel-header p {
    font-size: 1rem;
  }

  .carousel-card-content {
    width: 280px;
    height: 400px;
  }
}

@media (max-width: 576px) {
  .carousel-header h2 {
    font-size: 2rem;
  }

  .carousel-header p {
    font-size: .8rem;
  }

  .carousel-card-content {
    width: 380px;
  }

  .carousel-card-content h3 {
    font-size: 1.7rem;
    margin-bottom: 1.5rem;
  }

  .carousel-card-content p {
    font-size: large;
  }
}

@media (max-width: 400px) {
  .carousel-header h2 {
margin-top: 1rem;  }

  

  .carousel-card-content {
    width: 350px;
  }

  .carousel-card-content h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .carousel-section {
    
    height: 100vh;
  }

  
}

@media (max-width: 380px) {

  .carousel-section {
    
    height: 100vh;
  }
  
  .carousel-card-content {
    height: 350px;
  }
  
}

.custom-header {
  background-color: black;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;
}

.accordion-button.faq-button {
  color: rgb(127, 127, 127);
}

.accordion-button.faq-button:hover,
.accordion-button:not(.collapsed).faq-button {
  color: black;
}

.feature-accordion .accordion-header button:hover,
.feature-accordion .accordion-header button:not(.collapsed) {
  color: rgb(76, 165, 255);
}

.feature-accordion .accordion-header button {
  color: rgb(0, 0, 0);
  letter-spacing: .5px;
}


.feature-accordion .accordion-button {
  font-size: 1.7rem;
  letter-spacing: 3px !important;
  font-weight: bold;
  border-bottom: 2px solid #dfdfdf;
  border-top: none !important;
  background-color: white;
  color: rgb(31, 31, 31) !important;
}

.feature-accordion .accordion-button:not(.collapsed),
.feature-accordion .accordion-button:hover,
.feature-accordion .accordion-button:focus {
  color: rgb(76, 165, 255) !important;
  border-bottom-color: rgb(76, 165, 255);
}

.feature-accordion .accordion-button:focus {
  box-shadow: none;
}

.feature-accordion .accordion-body {
  padding: 20px;
  font-size: large;
}

.new-help {
  margin: 0;
  padding: 0;
  font-weight: 400 !important;
  font-size: 1.3rem !important;
}

.web-head {
  line-height: normal;
}

.accordion-button {
  text-align: left;
  font-weight: 500;
  font-size: large;

}

.faq-dark-bg .accordion-body {
  color: rgb(0, 0, 0);
}

.faq-dark-bg {
  margin-bottom: 4rem;
}

@media (min-width: 1600px) {
  .gallery-image {
    max-width: 800px;
    max-height: 600px;
  }

  .custom-header-top-padding {
    padding-top: 5rem !important;
  }
}

@media (max-width: 520px) {
  .feature-accordion .accordion-button {
    font-size: 1.4rem;
    letter-spacing: 2px !important;
  }
}

@media (max-width: 467px) {
  .web-head {
    font-size: 2.7rem;
  }

  

  .new-help {
    font-size: 1.2rem !important;
  }
}

@media (max-width: 415px) {
  .custom-header {
    padding: 15px 0;
  }

  .web-head {
    font-size: 2.5rem;
  }

  .new-help {
    font-size: 1.1rem !important;
  }
}

@media (max-width: 395px) {
  .custom-header {
    padding: 15px 0;
  }

  .web-head {
    font-size: 2.2rem;
  }

  .new-help {
    font-size: 1.1rem !important;
  }
}

@media (max-width: 370px) {
  .custom-header {
    padding: 15px 0;
  }

  .web-head {
    font-size: 2.1rem;
  }

  .new-help {
    font-size: 1rem !important;
  }
}

@media (min-width: 1600px) {
  .custom-header h1 {
    font-size: 3.5rem;
  }

  .custom-header p {
    font-size: 1.2rem;
  }
}

@media (max-width: 992px) {
  .feature-accordion {
    margin-top: 1rem;
  }
}

@media (max-width: 768px) {
  .mobile-spacing {
    margin-bottom: 1rem;
  }
}

@media (min-width: 2400px) {
.large-screen-hide {
  display: none;
}
}
.new-help {
    font-size: 1.1rem; /* Adjusted font-size */
    font-weight: 400; /* Matches fw-normal */
    line-height: 1.5; /* Adjusted line-height */
}

/* Hide the default Bootstrap caret for this specific accordion */
/* Hide the default Bootstrap caret */
.feature-accordion .accordion-button::after {
    display: none;
  }
  
  /* Styling for the custom icon */
  .feature-accordion .accordion-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    transition: transform 0.3s ease-in-out; /* Smooth spin animation */
  }
  
  .feature-accordion .icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    color: #fff;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .feature-accordion .icon-circle svg {
    font-size: 12px; /* Adjust the size of the icon as needed */
    transition: transform 0.3s ease-in-out; /* Smooth transform for the icon */
  }
  
  .feature-accordion .accordion-header.collapsed .icon-circle {
    background-color: #007bff !important; /* Background color when closed */
    transform: rotate(0deg); /* Start as a plus */
  }
  
  .feature-accordion .accordion-header:not(.collapsed) .icon-circle {
    background-color: #000000; /* Background color when opened */
    
    transform: rotate(45deg); /* Rotate to make it look like an X when opened */
  }


  .subheader-features-faq1 {
    text-align: left;
    margin-top: -1.5rem;
    font-size: 1.3rem;
  }


.web-head {
    color: rgb(116, 185, 255);
    font-weight: 749;
    font-size: 3rem;
}

/* Enhance the carousel container */
.bg-light {
    background-color: #f8f9fa!important; /* Ensure light grey background */
}

/* Specific to the unique carousel */
#uniqueClientWebsitesCarousel .carousel-inner img {
    height: auto; /* Changed to auto for maintaining aspect ratio based on width */
    width: 50px; /* Adjust this value as needed */
    object-fit: cover; /* Keeps the aspect ratio, cropping the image if necessary */
}

#uniqueClientWebsitesCarousel .carousel-control-prev-icon,
#uniqueClientWebsitesCarousel .carousel-control-next-icon {
    filter: invert(33%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(100%) contrast(100%);
}

/* Rounded corners for the unique carousel */
#uniqueClientWebsitesCarousel .carousel-inner {
    border-radius: 0.5rem;
}
.web-cards{
    margin-top: 6rem;
}
/* Ensure the shadow and rounded corners are visible for the unique carousel */
#uniqueClientWebsitesCarousel .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
/* Ensures carousel indicators appear as dots */
.unique-carousel-indicators li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #fff;
    
    cursor: pointer;
}

.unique-carousel-indicators li::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: currentColor;
    
    opacity: 0.5;
}

.unique-carousel-indicators li.active::before {
    opacity: 1;
}

/* Hiding any text or numbers within the indicators */
.unique-carousel-indicators li {
    text-indent: -9999px;
    overflow: hidden;
}





@media (max-width: 992px) {
    .web-spacing {
      margin-bottom: -5rem;
    }

    
}


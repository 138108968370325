.mb-custom {
    margin-bottom: 4rem; /* Example for 4rem margin */
}



.bullet-points-left {
    text-align: left;
  }
  

@media (max-width: 992px) {
    
    .divine-img {
        margin-bottom: 1.5rem;
    }
    
  }
/* Career Cards */
.career-card {
    background-color: rgba(255, 255, 255, 0.3);
    border: solid 3px whitesmoke;
    position: relative;
    overflow: hidden;
    transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out, background-color 0.4s ease-in-out;
    cursor: pointer;
    text-align: left; /* Align all content to the left */
  }


  .career-container {
    position: relative;
    z-index: 1; /* Ensure content is above background */
  }
  
  .svg-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1700 1000" width="1700" height="1000"><g fill="none" stroke="%231c8aff" stroke-width="2" opacity="0.3"><circle r="250" cx="100" cy="300"/></g><g fill="%231c8aff" opacity="0.15" stroke="none"><circle r="250" cx="200" cy="400"/></g></svg>');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1; /* Ensure the background is behind the content */
    animation: moveBackground 21s linear infinite;
  }
  
  @keyframes moveBackground {
    0% {
      background-position: center center;
    }
    50% {
      background-position: top left;
    }
    100% {
      background-position: center center;
    }
  }
  
  .career-container-fluid,
.form-container-fluid {
  position: relative;
  z-index: 1;
}

.svg-background,
.svg-background-flipped {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1700 1000" width="1700" height="1000"><g fill="none" stroke="%231c8aff" stroke-width="2" opacity="0.3"><circle r="250" cx="100" cy="300"/></g><g fill="%231c8aff" opacity="0.15" stroke="none"><circle r="250" cx="200" cy="400"/></g></svg>');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
  animation: moveBackground 21s linear infinite;
}

.svg-background-flipped {
  transform: scaleX(-1); /* Flip the SVG horizontally */
}

@keyframes moveBackground {
  0% {
    background-position: center center;
  }
  50% {
    background-position: top left;
  }
  100% {
    background-position: center center;
  }
}

  .custom-switch .custom-switch-input {
    width: 45px; /* Increase the width */
    height: 25px; /* Increase the height */
    background-color: #dedede; /* Darker background color */
    margin-right: 1rem;
  }
  
  .testi-1 {
    margin-top: 1rem;
    margin-bottom: 0rem;
  }
  
  .quote-icon {
    margin-left: -1.5rem !important;
    margin-bottom: -1rem;
  }
  
  .app-form-header {
    margin-bottom: 3rem;
  }
  
  .career-test {
    margin-top: -.5rem;
    font-size: large;
  }
  
  .career-test1 {
    color: rgb(212, 212, 212);
    padding-bottom: 2rem;
  }
  
  .career-container {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  
  .career-text {
    font-size: large;
  }
  
  .career-title {
    font-size: 1.8rem !important;
    letter-spacing: 2px;
    margin-top: 1rem;
    margin-bottom: .5rem;
    
  }
  
  .career-icon {
    margin-top: 1rem;
    font-size: 2rem;
  }
  
  .career-card:hover,
  .career-card:focus {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    background-color: rgb(68, 161, 255); /* Change background to blue */
    color: white; /* Change text color to white */
  }
  
  .career-card:hover .background-icon,
  .career-card:focus .background-icon {
    opacity: 1;
    transform: scale(1.1); /* Enlarge the icon */
  }
  
  /* Background Icon */
  .background-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9); /* Slightly shrink by default */
    color: rgba(255, 255, 255, 0.1);
    opacity: 0;
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
    pointer-events: none;
  }
  
  /* Testimonials Section */
  .testimonials-section {
    background-color: black;
  }
  
  .testimonials-section h2 {
    font-size: 2.5rem;
    font-weight: 700;
  }
  
  .testimonials-section p.lead {
    font-size: 1.2rem;
  }
  
  .testimonials-section .fa-quote-left {
    color: rgb(68, 161, 255);
  }
  
  /* Updated form styles for a more engaging look */
  .form-container {
    text-align: left;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border: 1px solid #ddd;
    padding: 2rem;
    background-color: #f9f9f9;
  }
  
  .dropdown-icon {
    right: 15px;
    top: 70%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #000000;
  }
  
  .form-control {
    position: relative;
    padding-right: 40px;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .form-control:focus {
    border-color: rgb(68, 161, 255);
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .btn-career {
    border: none;
    font-weight: 600;
    font-size: 1.1rem;
    border-radius: 5px;
    padding: 0.75rem 1.25rem;
  }
  
  .btn-career:hover {
    background-color: #0056b3;
  }
  
  .map-switch-label {
    font-weight: bold;
    font-size: large;
    letter-spacing: .5px;
    margin-top: .2rem;
  }
  
  .form-label {
    font-weight: 600;
    color: #343a40;
    font-size: 1rem;
  }
  
  .form-check-input {
    cursor: pointer; /* Add a pointer cursor on hover */
  }
  
  /* Responsive layout for the Join Our Team section */
  @media (max-width: 992px) {
    .career-form-container {
      order: 1; /* Ensure form is on top */
    }
    .career-map-container {
      order: 2; /* Ensure map is below the form */
      margin-top: 1.5rem; /* Add some spacing between the form and map */
    }
  }
  @media (max-width: 992px) {
  .svg-background,
  .svg-background-flipped {
    display: none;
  }
  .quote-icon {
    margin-left: 0rem !important;
    margin-bottom: 0rem;
  }
}

@media  (max-width: 500px) {
  .career-text {
    padding-bottom: 2rem;
  }
}

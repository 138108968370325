/* AnimatedBurger.css */
.burger-button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 30px;
  height: 30px;
  margin-right: 3px;
}

.burger-line {
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  margin: 3px 0;
  transition: all 0.4s ease;
}

.burger-line.one {
  transform: rotate(0) translate(0, 0);
}

.burger-line.two {
  opacity: 1;
}

.burger-line.three {
  transform: rotate(0) translate(0, 0);
}

.burger-button.open .burger-line.one {
  transform: rotate(45deg) translate(6px, 6px);
}

.burger-button.open .burger-line.two {
  opacity: 0;
}

.burger-button.open .burger-line.three {
  transform: rotate(-45deg) translate(5px, -5px);
}

@import url('https://fonts.googleapis.com/css2?family=K2D:wght@400;700&display=swap');

.footer-container {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #f3f6fd;
}

.social > a {
  display: inline-block;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  background-color: transparent;
  border: 2px solid white;
  color: white;
  text-align: center;
  line-height: 30px; /* This should match the height to center the icon */
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.social > a:hover {
  background-color: rgb(48, 152, 255); /* Background color on hover */
  color: white; /* Ensures the icon stays white */
  border-color: rgb(95, 175, 255);
  cursor: pointer;
}

.social > a .fa-lg {
  vertical-align: middle;
  line-height: 1; /* Resets line height for the icon itself */
}


.footer-card {
    width: 100%;
    padding: 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: black;
    color: white;
}

.heading-footer {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 1rem !important;
}

.footer-logo {
    width: 325px;
    height: auto;
}

.contact-us-column {
    margin-left: auto;
    padding-right: 60px;
    text-align: left;
}

.social > a {
    padding: 1%;
    font-size: 18px;
    color: #ffffff;
    margin-right: 10px;
    text-decoration: none;
}

.social > a:hover {
    cursor: pointer;
}

.heading {
    color: white;
}

.divider {
    border-top: 2px solid white;
}

.footer-style {
    font-size: 1rem !important;
}

.foot-contact {
  font-size: 1.2rem !important;
  color: white !important;
}

.footer-style {
  color: white;
  text-decoration: none;
}

.footer-style:hover {
  text-decoration: underline; /* Optional: underline on hover */
}

@media (max-width: 1024px) { /* Large devices (desktops, 992px and up) */
.footer-logo {
  width: 200px;
  height: auto;
}
}
.cookie-consent-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px 20px 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10001;
  }
  
  .cookie-text {
    margin: 0;
    font-size: large; /* Default size, adjust as needed */
    color: black;
    font-weight: 400;
    margin-left: 1.5rem;
  }
  
  .consent-button {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    min-width: 120px;
    font-size: 16px;
    margin-right: 10px;
    width: auto;
  }
  
  .accept-button {
    background-color: transparent;
    color: black;
    border: 2px solid black;
    font-weight: bold;
  }
  
  .accept-button.hover {
    background-color: black;
    color: white;
  }

  .decline-button:hover {
    background-color: rgb(0, 54, 142);
}

  
  .decline-button {
    background-color: rgb(0, 98, 255);
    color: white;
    border: none;
    font-weight: bold;
  }
  
  @media (max-width: 480px) {
    .cookie-text {
      font-size: medium;
      margin-left: 1rem;
    }
    .consent-button {
      padding: 8px 16px;
      min-width: 100px;
      font-size: 12px;
      margin: 5px 0; /* Added vertical margin for tiny mobile screens */
    }
  }
  
  @media (max-width: 768px) {
    .cookie-text {
      font-size: medium;
    }
    
    
  }

 
  
  @media (max-width: 760px) {
    .cookie-text {
      font-size: medium;
    }
    
  }

  @media (max-width: 720px) {
    .cookie-text {
      font-size: small;
    }
    
  }

  @media (max-width: 655px) {
    .cookie-text {
      font-size: medium;
    }
    .consent-button {
        padding: 8px 16px;
        min-width: 100px;
        font-size: 12px;
        margin: 5px 0; /* Added vertical margin for tiny mobile screens */
      }
    
  }
/* Modal Styling */
.pathway-popup-body {
  background-color: #ffffff;
  border-radius: 20px;
  text-align: center;
  padding: 2rem;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
}

/* Close Button */
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 2rem;
  color: #000;
  cursor: pointer;
}

/* Icon Container */
.icon-container-pathway {
  margin: 1.5rem 0;
}

.icon-circle-pathway {
  background-color: #ffffff;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-bottom: -.5rem;
}

.pathlink-logo {
  width: 100%;
  height: auto;
  object-fit: contain;
  position: relative;
}

.link {
  color: rgb(68, 161, 255);
}

/* Success Message */
.success-message-pathway {
  color: rgb(68, 161, 255);
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 1rem;
}

/* Popup Title */
.popup-title {
  font-size: 1.8rem;
  color: #373636;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Description Text */
.pathway-popup-description {
  font-size: 1rem;
  color: #555555;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

/* Form Input Styling */
.popup-input {
  border: 1px solid rgb(68, 161, 255);
  border-radius: 25px;
  padding: 0.8rem 1.2rem;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}

/* Button Styling */
.popup-button {
  background: rgb(68, 161, 255);
  color: #ffffff;
  font-weight: bold;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
}

.popup-button:hover {
  background: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .pathway-popup-body {
      padding: 1.5rem;
  }

  .popup-title {
      font-size: 1.5rem;
  }

  .pathway-popup-description {
      font-size: 0.9rem;
  }
}

@media (max-width: 500px) {
  .pathway-modal-dialog {
      max-width: 90% !important;
      margin: 0 auto !important;
      padding-top: 30% !important;
      padding-bottom: 50% !important;
  }
  .pathway-popup-body {
      border-radius: 0px !important;
  }
}

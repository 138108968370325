.jumbotron {
  background-color: #000;
  color: #fff;
  padding: 3rem 1rem;
  margin-bottom: 0;
}

.get-a-quote-section {
  border: 1px solid #000;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.6);
  padding: 2rem;
  border-radius: 8px;
}

.get-quote h2 {
  font-weight: 800;
  color: rgb(116, 185, 255);
}

.what-you {
  font-weight: 700;
  font-size: xx-large;
}

.form-control {
  border: none;
  border-bottom: 1px solid #818181;
  border-radius: 0;
  background-color: transparent;
}

.form-control:focus {
  border-bottom: 2px solid rgb(116, 185, 255);
  outline: none;
  box-shadow: none;
}

.thank-you-message {
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.thank-you-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #343a40;
}

.thank-you-text {
  font-size: 1rem;
  color: #6c757d;
}

.email-response {
  margin-top: 2rem;
}

.response-title {
  font-size: 1.2rem;
  font-weight: 700;
  color: #343a40;
}


.mobile-pricing1 {
  background-color: rgba(0, 0, 0, 0.85);
  padding: 3rem;
}

.response-list {
  font-size: 1rem;
  color: #6c757d;
}

.pricing-title {
  font-weight: 700;
  font-size: xx-large;
  color: rgb(116, 185, 255);
}
